// authentication services
import { message } from "antd";
import axiosDefault from "utils/axios";

import { genBiasBoolean } from "utils/testUtils/builders/common.builder";
import { buildUser } from "utils/testUtils/builders/user.builder";
import { LogInByTokenRes, LogInByCredentials } from "./auth.service.types";
import { User } from "types/user.types";

export const fetchAuthToken = async (
  token: string | null
): Promise<LogInByTokenRes> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (genBiasBoolean(1)) {
        resolve({
          token: "FAKE_TOKEN",
          user: {
            id: "1",
            name: "Santiago",
            lastName: "Benítez",
            role: {
              id: "1",
              // TODO: cambiar por nombre cuando devuelva el endpoint
              name: "1"
            }
          }
        });
      } else {
        message.error("Sesión expiró");
        reject(new Error("Server: Auth token expired"));
      }
    }, 500);
  });
};

export const logInUser = async (
  usuario: string,
  password: string
): Promise<LogInByCredentials> => {
  const { data } = await axiosDefault.post("/api/farmaEnlace/login", {
    usuario,
    password
  });
  const { user, customToken } = data;
  const { id, nombre, apellido, email, ...attributes } = user;
  const mappedUser: User = {
    id,
    uid: user.cedula,
    name: nombre,
    lastName: user.apellido,
    email,
    role: {
      id: user.atributos.roleId,
      // TODO: cambiar por nombre cuando devuelva el endpoint
      name: user.atributos.roleId
    },
    attributes: attributes
  };
  return { user: mappedUser, token: customToken };
};

export const updateUserService = async (user: User): Promise<User> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (genBiasBoolean(1)) {
        resolve(buildUser({ ...user }));
      } else {
        reject(new Error("Server: Fail to update user"));
      }
    }, 1000);
  });
};

export const logOutService = async (authToken: string) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (genBiasBoolean(1)) {
        resolve();
      } else {
        reject(new Error("Server: Fail to log user out"));
      }
    }, 500);
  });
};
