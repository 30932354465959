// Knowledge base services
import axiosDefault from "../../utils/axios";
import { KnowledgeBaseCategory, Article } from "types/base.types";
import { getAuthorization } from "../firebase/firebase.service";

export const fetchCategories = async (): Promise<KnowledgeBaseCategory[]> => {
  try {
    const authorizationToken = await getAuthorization();
    const { data } = await axiosDefault.get<KnowledgeBaseCategory[]>(
      `/api/knowledge/categories`,
      {
        headers: {
          Authorization: authorizationToken
        }
      }
    );

    return data;
  } catch (error) {
    throw new Error("Failed to fetch article categories");
  }
};

export const fetchArticle = async (articleId: number): Promise<Article> => {
  try {
    const authorizationToken = await getAuthorization();
    const { data } = await axiosDefault.get<Article>(
      `/api/knowledge/articles/${articleId}`,
      {
        headers: {
          Authorization: authorizationToken
        }
      }
    );

    return data;
  } catch (error) {
    throw new Error("Failed to fetch the article");
  }
};

export const searchArticles = async (text: string): Promise<Article> => {
  try {
    const authorizationToken = await getAuthorization();
    const { data } = await axiosDefault.get<Article>(
      `/api/knowledge/articles`,
      {
        headers: {
          Authorization: authorizationToken
        },
        params: {
          text,
          limit: 1000,
          page: 1
        }
      }
    );

    return data;
  } catch (error) {
    throw new Error("Failed to fetch the article");
  }
};
