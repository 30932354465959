// Tickets services
import {
  Ticket,
  TicketBox,
  Scores,
  Assignee,
  Subcategory
} from "../../types/ticket.types";

import folderSVG from "assets/images/inbox/folder.svg";
import axiosDefault from "utils/axios";
import {
  FetchBoxesService,
  TicketRequest,
  TicketComment,
  TicketAssignee,
  TicketEntry
} from "./tickets.service.types";
import { getAuthorization } from "services/firebase/firebase.service";
import { Category, SubcategoryConfig } from "types/ticket.types";
import { SubcategoryConfigV2 } from "../../types/ticket.types";
import { message } from "antd";

export const fetchTicketBoxes = async (
  assigneeId: number
): Promise<TicketBox[]> => {
  try {
    const authorizationToken = await getAuthorization();
    const { data } = await axiosDefault.get<FetchBoxesService[]>(
      "/api/tickets/filter/author",
      {
        params: {
          assigneeId
        },
        headers: {
          Authorization: authorizationToken
        }
      }
    );

    const ticketBoxes = data.map((box, index) => {
      const { id, count, name } = box;
      return {
        id: `${id}`,
        name,
        icon: folderSVG,
        count,
        weigth: index + 1
      };
    });

    return ticketBoxes;
  } catch (error) {
    throw new Error("Failed to fetch the boxes");
  }
};

export const fetchBoxTickets = async (
  assigneeId: number,
  boxId: string,
  valueToSearch?: string
): Promise<Ticket[]> => {
  try {
    const authorizationToken = await getAuthorization();
    const pagination = {
      page: 1,
      size: 1000
    };
    const sort = {
      predicate: "id",
      type: "DESC"
    };

    const { data } = await axiosDefault.get(
      `/api/tickets/ticketsByAuthor/${assigneeId}`,
      {
        params: {
          filterId: boxId,
          pagination,
          sort,
          search: valueToSearch
        },
        headers: {
          Authorization: authorizationToken
        }
      }
    );
    const tickets = data.data;
    const formattedTickets: Ticket[] = tickets.map((ticket: any) => {
      return {
        id: ticket.id,
        // TODO: poner el valor correcto cuando ya exista esa funcionalidad
        read: true,
        responsible: {
          id: ticket.assignee.id,
          name: ticket.assignee.name
        },
        author: ticket.author ?? "Anónimo",
        subject: ticket.subject,
        flowStatus: ticket.globalStatus,
        flowStatusUpdatedAt: ticket.updatedAt,
        priority: ticket.priority,
        createdAt: ticket.createdAt,
        updatedAt: ticket.updatedAt,
        score: ticket.score,
        ...ticket
      };
    });
    return formattedTickets;
  } catch (error) {
    throw new Error("Failed to fetch fetchIssues");
  }
};

export const fetchTicketCategories = async (): Promise<Category[]> => {
  try {
    const authorizationToken = await getAuthorization();
    const msRoute = "/api/tickets/category";
    const { data } = await axiosDefault.get(`${msRoute}`, {
      params: {
        clientVisible: true
      },
      headers: {
        Authorization: authorizationToken
      }
    });
    return data;
  } catch (e) {
    throw new Error("Failed to fetch the categories");
  }
};

export const fetchSubcategoryConfig = async (
  statusId: number,
  subcategoryId: number
): Promise<SubcategoryConfig | null> => {
  try {
    const authorizationToken = await getAuthorization();
    const msRoute = `/api/tickets/configurationBySubcategory/${subcategoryId}`;
    const { data } = await axiosDefault.get(`${msRoute}`, {
      params: {
        statusId
      },
      headers: {
        Authorization: authorizationToken
      }
    });
    const subcategoryConfig = data?.length ? data[0] : null;
    return subcategoryConfig;
  } catch (e) {
    throw new Error("Failed to fetch the categories");
  }
};

export const createTicket = async (ticketRequestBody: TicketRequest) => {
  try {
    const authorizationToken = await getAuthorization();
    const msRoute = `/api/tickets/ticket`;
    await axiosDefault.post(`${msRoute}`, ticketRequestBody, {
      headers: {
        Authorization: authorizationToken
      }
    });
  } catch (e) {
    const { code, error } = e?.response?.data;
    if (code === 422 && error.length > 0) {
      throw new Error(error[0].value);
    } else {
      throw new Error("Error al crear el ticket");
    }
  }
};

export const updateTicket = async (
  ticketId: number,
  ticketCommentBody: TicketComment
) => {
  try {
    const authorizationToken = await getAuthorization();
    const msRoute = `/api/tickets/ticket/${ticketId}`;
    await axiosDefault.put(`${msRoute}`, ticketCommentBody, {
      headers: {
        Authorization: authorizationToken
      }
    });
  } catch (e) {
    throw new Error("Failed to save comment");
  }
};

export const rateTicket = async (ticketId: number, scores: Scores) => {
  try {
    const authorizationToken = await getAuthorization();
    const msRoute = `/api/tickets/updateScore/${ticketId}`;
    // TODO: Poner los status de constantes
    await axiosDefault.put(
      `${msRoute}`,
      {
        score: scores,
        globalStatus: "CLOSED",
        status: 4
      },
      {
        headers: {
          Authorization: authorizationToken
        }
      }
    );
  } catch (e) {
    throw new Error("Failed to rate ticket");
  }
};

export const fetchAssigneeByExternalId = async (
  externalId: string
): Promise<Assignee> => {
  try {
    const authorizationToken = await getAuthorization();
    const msRoute = `/api/tickets/assigneeByExternalId/${externalId}`;
    const { data } = await axiosDefault.get(`${msRoute}`, {
      headers: {
        Authorization: authorizationToken
      }
    });
    return data;
  } catch (e) {
    throw new Error("Failed to fetch assignee");
  }
};

export const fetchEntries = async (ticketId: number) => {
  try {
    const authorizationToken = await getAuthorization();
    const msRoute = `/api/tickets/entries`;
    // TODO: Poner los status de constantes

    const { data } = await axiosDefault.get(`${msRoute}`, {
      params: {
        events: "TICKET.COMMENT,TICKET.RESOLVED",
        isPublic: 1,
        ticket: ticketId
      },
      headers: {
        Authorization: authorizationToken
      }
    });

    return data;
  } catch (e) {
    throw new Error("Failed to fetch entries");
  }
};

export const updateTicketAssignee = async (
  assigneeId: number,
  TicketAssigneeBody: TicketAssignee
) => {
  try {
    const authorizationToken = await getAuthorization();
    const msRoute = `/api/tickets/assignee/${assigneeId}`;
    await axiosDefault.put(`${msRoute}`, TicketAssigneeBody, {
      headers: {
        Authorization: authorizationToken
      }
    });
  } catch (e) {
    throw new Error("Failed to save additional info");
  }
};

export const saveComment = async (ticketId: string, comment: TicketEntry) => {
  try {
    const authorizationToken = await getAuthorization();
    const msRoute = `/api/tickets/createComment/${ticketId}`;
    const { data } = await axiosDefault.post(`${msRoute}`, comment, {
      headers: {
        Authorization: authorizationToken
      }
    });
    return data;
  } catch (e) {
    throw new Error("Failed to create comment");
  }
};

export const fetchSubcategoryById = async (
  subcategoryId: number
): Promise<Subcategory> => {
  try {
    const authorizationToken = await getAuthorization();
    const { data } = await axiosDefault.get<Subcategory>(
      `/api/tickets/subcategory/${subcategoryId}`,
      {
        headers: {
          Authorization: authorizationToken
        }
      }
    );

    return data;
  } catch (error) {
    throw new Error("Failed to fetch a subcategory by id");
  }
};

export const fetchSubcategoryConfigV2 = async (
  statusId: number,
  subcategoryId: number
): Promise<SubcategoryConfigV2 | undefined> => {
  try {
    const authorizationToken = await getAuthorization();
    const msRoute = `/api/tickets/v2/configurationBySubcategory/${subcategoryId}`;
    const { data } = await axiosDefault.get(`${msRoute}`, {
      params: {
        statusId
      },
      headers: {
        Authorization: authorizationToken
      }
    });
    return data;
  } catch (e) {
    message.error("Ocurrió un error al seleccionar categoria y subcategoria");
  }
};
