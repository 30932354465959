import firebase from "firebase/app";

export const initFirebase = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp({
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`
    });
  }
};
