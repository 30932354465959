import { ThunkDispatch, AppState } from "redux/store.types";
import { message } from "antd";

import {
  fetchTicketBoxes,
  fetchBoxTickets
} from "services/tickets/tickets.service";
import { Entry, Ticket, TICKET_FLOW_STATUS } from "types/ticket.types";
import { findTicket } from "utils/tickets";

export const GET_TICKET_BOXES = "GET_TICKET_BOXES";
export const GET_BOX_TICKETS = "GET_BOX_TICKETS";
export const ADD_ENTRY = "ADD_ENTRY";
export const ADD_TICKET = "ADD_TICKET";
export const REMOVE_TICKET = "REMOVE_TICKET";
export const UPDATE_TICKET = "UPDATE_TICKET";
export const SET_TICKET_SOCKET_CONNECTED = "SET_TICKET_SOCKET_CONNECTED";

// Fetch box tickets
export const getTicketBoxes = () => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  try {
    const assigneeId = getState().Auth.user?.assignee?.id;
    if (assigneeId) {
      const ticketBoxes = await fetchTicketBoxes(assigneeId);
      return dispatch({ type: GET_TICKET_BOXES, payload: ticketBoxes });
    }
  } catch (error) {
    message.error("Error al cargar las bandejas de tickets");
  }
};

// Fetch tickets of a ticket box
export const getBoxTickets = (
  ticketBoxId: string,
  searchValue?: string
) => async (dispatch: ThunkDispatch, getState: () => AppState) => {
  const assigneeId = getState().Auth.user?.assignee?.id;
  if (assigneeId) {
    const tickets = await fetchBoxTickets(assigneeId, ticketBoxId, searchValue);
    return dispatch({
      type: GET_BOX_TICKETS,
      payload: { id: ticketBoxId, tickets }
    });
  }
};

export const setTicketSocketConnected = (isConnected: boolean) => async (
  dispatch: ThunkDispatch
) => {
  dispatch({ type: SET_TICKET_SOCKET_CONNECTED, payload: isConnected });
};

export const addEntry = (entry: Entry, ticketId: string, boxId: string) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: ADD_ENTRY, payload: { entry, boxId, ticketId } });
};

export const addTicket = (ticket: Ticket, boxId: string) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: ADD_TICKET, payload: { ticket, boxId } });
};

export const removeTicket = (ticketId: string, boxId: string) => (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  dispatch({ type: REMOVE_TICKET, payload: { ticketId, boxId } });
};

export const updateTicket = (
  ticket: Partial<Ticket>,
  ticketId: string,
  boxId: string
) => (dispatch: ThunkDispatch, getState: () => AppState) => {
  dispatch({ type: UPDATE_TICKET, payload: { ticket, ticketId, boxId } });
};

export const updateTicketFlowStatus = (
  flowStatus: TICKET_FLOW_STATUS,
  ticketId: string,
  boxId: string
) => (dispatch: ThunkDispatch, getState: () => AppState) => {
  const boxes = getState().Tickets.ticketBoxes;
  const oldTicket = findTicket(ticketId, boxId, boxes);
  if (!oldTicket) {
    throw new Error(
      "There must be a pre-existent ticket to continue updating the tickect statusFlow"
    );
  }
  const ticket = { ...oldTicket, flowStatus };
  const newBox = boxes.find(box => {
    return box.flowStatus === flowStatus;
  });
  if (!newBox) {
    throw new Error(
      `We couldn't find a box that correspond to the flow status ${flowStatus}`
    );
  }
  dispatch(addTicket(ticket, newBox.id));
  dispatch(removeTicket(ticketId, boxId));
};
