import moment from "moment";
import CONSTANTS from "config/constants";
import { User, AuthResponse } from "../types/user.types";

// Returns the authToken if it is found on localStorage and it is not expired
export const handleSessionExpiration = (): AuthResponse | null => {
  const authTokenKey = process.env.REACT_APP_AUTH_TOKEN_KEY as string;
  const authToken = localStorage.getItem(authTokenKey);
  const userString = localStorage.getItem(`${authTokenKey}-user`);
  const user: User = userString ? JSON.parse(userString) : null;
  const authTokenExpiracyKey = process.env
    .REACT_APP_AUTH_EXPIRACY_TOKEN_KEY as string;
  const authTokenExpiracy = localStorage.getItem(authTokenExpiracyKey);
  const saveSession = localStorage.getItem(`${authTokenKey}-session`);
  if (!authTokenExpiracy) {
    cleanLocalStorageAuth();
    return null;
  }

  const expired = moment().isAfter(moment(authTokenExpiracy), "seconds");

  if (expired && !saveSession) {
    cleanLocalStorageAuth();
    return null;
  }
  return { authToken, user };
};

// Refresh expiration time on local storage
export const refreshLocalStorageAuth = (
  authToken: string,
  user: User,
  save?: boolean
) => {
  console.log("REFRESH LOCAL STORAGE");
  const authTokenKey = process.env.REACT_APP_AUTH_TOKEN_KEY as string;
  const oldToken = localStorage.getItem(authTokenKey);

  if (oldToken && save === false) {
    cleanLocalStorageAuth();
  }

  if (save) {
    localStorage.setItem(`${authTokenKey}-session`, JSON.stringify(save));
  }

  // Set the auth token in localStorage
  localStorage.setItem(authTokenKey, authToken);
  localStorage.setItem(`${authTokenKey}-user`, JSON.stringify(user));
  // Set the auth token expiration date in localStorage
  const authTokenExpiracyKey = process.env
    .REACT_APP_AUTH_EXPIRACY_TOKEN_KEY as string;
  const defaultExpiration = CONSTANTS.AUTH_TOKEN_DEFAULT_EXPIRATION;
  const expiration = new Date(
    new Date().setSeconds(new Date().getSeconds() + defaultExpiration)
  ).toISOString();
  localStorage.setItem(authTokenExpiracyKey, expiration);
};

// Save session expiration key into local storage
export const setSessionExpirationKey = () => {
  const defaultExpiration = CONSTANTS.AUTH_TOKEN_DEFAULT_EXPIRATION;
  const expirationKey = CONSTANTS.AUTH_TOKEN_EXPIRATION_KEY;
  const expiration = new Date(
    new Date().setSeconds(new Date().getSeconds() + defaultExpiration)
  ).toISOString();
  localStorage.setItem(expirationKey, expiration);
};

export const checkExpiration = (): boolean => {
  const expirationKey = CONSTANTS.AUTH_TOKEN_EXPIRATION_KEY;
  const expirationTime = localStorage.getItem(expirationKey);
  if (!expirationTime) {
    return false;
  }
  const expired = moment().isAfter(moment(expirationTime), "seconds");
  return expired;
};

// Remove auth related variables from local storage
export const cleanLocalStorageAuth = () => {
  const authTokenKey = process.env.REACT_APP_AUTH_TOKEN_KEY as string;
  const authTokenExpiracyKey = process.env
    .REACT_APP_AUTH_EXPIRACY_TOKEN_KEY as string;
  localStorage.removeItem(authTokenKey);
  localStorage.removeItem(authTokenExpiracyKey);
  localStorage.removeItem(`${authTokenKey}-session`);
};
