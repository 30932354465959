import { lighten } from "polished";

const palette = {
  empty: "", // Fixes a bug with styled components that appends a coma
  primary: "#0059A1",
  "primary-light": lighten(0.3, "#fff"),
  secondary: "#FCF8F8",
  grey: [
    "#DADCE0", // 0
    "#797979", // 1
    "#E6ECF2", // 2
    "#F2F2F2", // 3
    "#CCCCCC", // 4
    "#E5E5E5", // 5
    "rgba(0, 0, 0, 0.45)", // 6
    "#FAFAFA", // 7
    "rgba(0, 0, 0, 0.25)", // 8
    "#E8E8E8", // 9
    "#676767", // 10
    "#C4C4C4", // 11
    "#595959", // 12
    "#262626;" // 13
  ],
  fg: "var(--palette-grey-5)",
  bg: "var(--palette-secondary)",
  black: "#000",
  white: "#fff",
  activity: "#F2C94C",
  blue: "#0059A1",
  "blue-light": lighten(0.2, "#0059A1")
};

const fonts = {
  primary: "'Montserrat', sans-serif"
};

const sizes = {
  page: {
    minWidth: "100vw",
    maxWidth: "100vw",
    minHeight: "100vh",

    content: {
      width2: "120rem",
      width: "88.4rem"
    }
  },
  navbar: {
    height: "4.8rem"
  },
  article: {
    preview: {
      width: "66.4rem"
    }
  }
};

export default { palette, fonts, sizes };
