import firebase from "firebase/app";
import { FirebaseUser } from "./firebase.service.types";

export const signInWithCustomToken = async (
  token: string
): Promise<FirebaseUser | null> => {
  const loginPayload = await firebase.auth().signInWithCustomToken(token);

  const { user } = loginPayload;
  if (!user) {
    throw new Error("Error retrieving user data from firebase");
  }
  const userData = {
    email: user.email,
    uid: user.uid,
    emailVerified: user.emailVerified,
    refreshToken: user.refreshToken
  };

  return userData;
};

export const getAuthorization = async () => {
  const currentUser = firebase.auth().currentUser;
  if (!currentUser) {
    return null;
  }
  const idToken = await currentUser.getIdToken(true);
  return `Bearer ${idToken}`;
};
