import { getAuthorization } from "services/firebase/firebase.service";
import axiosDefault from "utils/axios";
import {
  Employee,
  OfficeData,
  Office,
  OfficeSchedule,
  Franchisee,
  Bank,
  DepositeInquiry
} from "../../types/farmaenlace.types";

export const fetchEmployee = async (
  documentId: string
): Promise<Employee[]> => {
  try {
    const authorizationToken = await getAuthorization();
    const msRoute = `/api/farmaEnlace/employee`;
    const { data } = await axiosDefault.post(
      `${msRoute}`,
      {
        employeeId: documentId
      },
      {
        headers: {
          Authorization: authorizationToken
        }
      }
    );
    const employee = data;
    return employee;
  } catch (e) {
    throw new Error("Failed to fetch employee");
  }
};

export const fetchOffice = async (officeId: string): Promise<OfficeData> => {
  try {
    const authorizationToken = await getAuthorization();
    const msRoute = `/api/farmaEnlace/office`;
    const { data } = await axiosDefault.post(
      `${msRoute}`,
      {
        officeId: officeId
      },
      {
        headers: {
          Authorization: authorizationToken
        }
      }
    );
    const office: Office = data.Oficinas[0] ?? null;
    const schedule: OfficeSchedule = data.HorariosOficina[0] ?? null;
    const franchisee: Franchisee = data.DatosFranquiciado[0] ?? null;
    const officeData = { office, schedule, franchisee };
    return officeData;
  } catch (e) {
    throw new Error("Failed to fetch office data");
  }
};

export const fetchBanks = async (): Promise<Bank[]> => {
  try {
    const authorizationToken = await getAuthorization();
    const msRoute = `/api/farmaEnlace/bankCatalogs`;
    const { data } = await axiosDefault.post(
      `${msRoute}`,
      {},
      {
        headers: {
          Authorization: authorizationToken
        }
      }
    );
    return data.Bancos;
  } catch (e) {
    throw new Error("Failed to fetch banks");
  }
};

export const fetchDepositInquiry = async (
  depositInquiry: DepositeInquiry
): Promise<any> => {
  try {
    const authorizationToken = await getAuthorization();
    const msRoute = `/api/farmaEnlace/depositInquiry`;

    let params: any = {
      codBank: depositInquiry.bank,
      account: depositInquiry.account,
      depositDate: depositInquiry.depositDate
    };

    if (Number(depositInquiry.totalAmount)) {
      params = { ...params, totalAmount: Number(depositInquiry.totalAmount) };
    }

    if (depositInquiry.documentNumber) {
      params = {
        ...params,
        documentNumber: String(depositInquiry.documentNumber)
      };
    }

    if (
      Number(depositInquiry.totalAmount) &&
      String(depositInquiry.documentNumber)
    ) {
      params = {
        ...params,
        totalAmount: Number(depositInquiry.totalAmount),
        documentNumber: String(depositInquiry.documentNumber)
      };
    }
    const { data } = await axiosDefault.post(`${msRoute}`, params, {
      headers: {
        Authorization: authorizationToken
      }
    });
    return data;
  } catch (e) {
    throw new Error("Failed to fetch depositInquiry");
  }
};
