import {
  TicketsState as State,
  TicketsActions as Actions,
  GetBoxTicketsAction,
  AddEntryAction,
  RemoveTicketAction,
  UpdateTicketAction,
  AddTicketAction
} from "./tickets.types";
import { GET_TICKET_BOXES, GET_BOX_TICKETS } from "./tickets.actions";
import {
  ADD_ENTRY,
  ADD_TICKET,
  REMOVE_TICKET,
  SET_TICKET_SOCKET_CONNECTED
} from "./tickets.actions";
import { UPDATE_TICKET } from "./tickets.actions";
import { findTicketBoxById, sortByWeight } from "utils/tickets";
import { TicketBox } from "types/ticket.types";

const initialState: State = {
  ticketBoxes: [],
  ticketSocketConnected: false
};

const reducer = (state = initialState, action: Actions): State => {
  switch (action.type) {
    case GET_TICKET_BOXES:
      return setTicketBoxes(state, action.payload);
    case GET_BOX_TICKETS:
      return setBoxTickets(state, action.payload);
    case ADD_ENTRY:
      return addEntry(state, action.payload);
    case ADD_TICKET:
      return addTicket(state, action.payload);
    case REMOVE_TICKET:
      return removeTicket(state, action.payload);
    case UPDATE_TICKET:
      return updateTicket(state, action.payload);
    case SET_TICKET_SOCKET_CONNECTED:
      return { ...state, ticketSocketConnected: action.payload };
    default:
      return state;
  }
};

const setTicketBoxes = (state: State, boxes: TicketBox[]) => {
  return { ...state, ticketBoxes: boxes.sort(sortByWeight) };
};

const setBoxTickets = (
  state: State,
  payload: GetBoxTicketsAction["payload"]
) => {
  const { id, tickets } = payload;
  const box = findTicketBoxById(state.ticketBoxes, id);
  if (!box) {
    throw new Error(`Ticket box of id ${id}, counld not be found.`);
  }
  const newBox = { ...box, tickets };
  const filteredTicketBoxes = state.ticketBoxes.filter(box => {
    return box.id !== id;
  });
  const newTicketBoxes = [...filteredTicketBoxes, newBox].sort(sortByWeight);

  return { ...state, ticketBoxes: newTicketBoxes };
};

const addEntry = (state: State, payload: AddEntryAction["payload"]) => {
  const oldTicketBoxes = [...state.ticketBoxes];
  const oldBox = oldTicketBoxes.find(box => box.id === payload.boxId);
  if (!oldBox) {
    throw new Error("Old box should have been found");
  }
  const oldTickets = [...oldBox.tickets!];
  if (!oldTickets) {
    throw new Error("Old tickets should have been found");
  }
  const oldTicket = oldTickets.find(ticket => {
    return ticket.id === payload.ticketId;
  });
  if (!oldTicket) {
    throw new Error("Old ticket should have been found");
  }
  const entries = [...oldTicket.entries!, payload.entry];
  const ticket = { ...oldTicket, entries };
  const tickets = oldTickets.map(oldTicket => {
    if (oldTicket.id === payload.ticketId) {
      return ticket;
    }
    return oldTicket;
  });
  const box = { ...oldBox, tickets };
  const ticketBoxes = oldTicketBoxes.map(oldBox => {
    if (oldBox.id === payload.boxId) {
      return box;
    }
    return oldBox;
  });
  return { ...state, ticketBoxes };
};

const addTicket = (state: State, payload: AddTicketAction["payload"]) => {
  const { ticket, boxId } = payload;
  const oldTicketBoxes = [...state.ticketBoxes];
  const oldBox = oldTicketBoxes.find(box => box.id === boxId);
  if (!oldBox) {
    throw new Error("Old box should have been found");
  }
  const tickets = [ticket, ...oldBox.tickets!];
  const box = { ...oldBox, tickets };
  const ticketBoxes = oldTicketBoxes.map(oldBox => {
    if (oldBox.id === boxId) {
      return box;
    }
    return oldBox;
  });

  return { ...state, ticketBoxes };
};

const removeTicket = (state: State, payload: RemoveTicketAction["payload"]) => {
  const { ticketId, boxId } = payload;
  const oldTicketBoxes = [...state.ticketBoxes];
  const oldBox = oldTicketBoxes.find(box => box.id === boxId);
  if (!oldBox) {
    throw new Error("Old box should have been found");
  }
  const tickets = oldBox.tickets!.filter(ticket => {
    return ticket.id !== ticketId;
  });
  const box = { ...oldBox, tickets };
  const ticketBoxes = oldTicketBoxes.map(oldBox => {
    if (oldBox.id === boxId) {
      return box;
    }
    return oldBox;
  });

  return { ...state, ticketBoxes };
};

const updateTicket = (state: State, payload: UpdateTicketAction["payload"]) => {
  const { ticket, ticketId, boxId } = payload;
  const oldTicketBoxes = [...state.ticketBoxes];
  const oldBox = oldTicketBoxes.find(box => box.id === boxId);
  if (!oldBox) {
    throw new Error("Old box should have been found");
  }
  const oldTickets = [...oldBox.tickets!];
  if (!oldTickets) {
    throw new Error("Old tickets should have been found");
  }
  const oldTicket = oldTickets.find(ticket => {
    return ticket.id === ticketId;
  });
  if (!oldTicket) {
    throw new Error("Old ticket should have been found");
  }
  const newTicket = { ...oldTicket, ...ticket };
  const tickets = oldTickets.map(oldTicket => {
    if (oldTicket.id === ticketId) {
      return newTicket;
    }
    return oldTicket;
  });
  const box = { ...oldBox, tickets };
  const ticketBoxes = oldTicketBoxes.map(oldBox => {
    if (oldBox.id === boxId) {
      return box;
    }
    return oldBox;
  });
  return { ...state, ticketBoxes };
};

export default reducer;
