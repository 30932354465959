import { ThunkDispatch, AppState } from "redux/store.types";
import firebase from "firebase/app";

import { User } from "types/user.types";
import { message } from "antd";
import { refreshLocalStorageAuth } from "utils/session";
import { cleanLocalStorageAuth } from "utils/session";
import { signInWithCustomToken } from "services/firebase/firebase.service";
import { fetchAssigneeByExternalId } from "../../services/tickets/tickets.service";
import { handleSessionExpiration } from "../../utils/session";
import {
  fetchEmployee,
  fetchOffice
} from "../../services/farmaenlace/farmaenlace.service";
import {
  logInUser,
  updateUserService,
  logOutService
} from "services/auth/auth.service";
import CONSTANTS from "config/constants";

export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";
export const UPDATE_USER = "UPDATE_USER";
export const FETCH_USER_DATA = "FETCH_USER_DATA";

// Fetches the authToken and sets the user as logged in or logs hims out
export const logIn = (
  username: string,
  password: string,
  rememberme: boolean
) => async (dispatch: ThunkDispatch) => {
  try {
    const { token, user } = await logInUser(username, password);
    if (!token) {
      throw new Error("Failed to signin");
    }
    await signInWithCustomToken(token);

    if (user.uid) {
      dispatch(fetchUserData(user.uid));
    }
    dispatch(setLoggedIn(token, user, rememberme));
  } catch (e) {
    console.log(e, "ERROR");
    message.error("Sign In falló, inténtelo nuevamente");
  }
};

export const fetchUserData = (externalId: string) => async (
  dispatch: ThunkDispatch
) => {
  try {
    const assignee = await fetchAssigneeByExternalId(externalId);
    const employee = await fetchEmployee(externalId);
    const officeData = await fetchOffice(employee[0].additionalInfo.officeCode);
    dispatch({
      type: FETCH_USER_DATA,
      payload: { assignee, employee, ...officeData }
    });
  } catch (e) {
    message.error(
      "Existe un error con sus datos, pongase en contacto con su supervisor"
    );
    dispatch(logOut());
  }
};

// Checks whether the user is already loggedIn in the server and sets it as logged in locally
export const isLoggedIn = () => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  try {
    const auth = getState().Auth;
    // Check if auth token is stored in local storage
    firebase.auth().onAuthStateChanged(async firebaseUser => {
      if (firebaseUser) {
        const { authToken, user } = (await handleSessionExpiration()) ?? {};
        console.log(authToken, "AUTH TOKEN");
        const localToken = authToken ?? auth.authToken;
        // if authToken in localStorage or redux, check if also signed in in the server
        if (localToken || auth.loggedIn) {
          // TODO: Implementar la revisión del token en el server
          // const response = await fetchAuthToken(localToken);
          if (authToken && user) {
            if (user.uid) {
              dispatch(fetchUserData(user.uid));
            }
            dispatch(setLoggedIn(authToken, user));
          } else {
            dispatch(logOut());
          }
        } else {
          dispatch(logOut());
        }
      }
    });
  } catch (e) {
    dispatch(logOut());
  }
};

// Fetches user data and sets the app as logged in
export const setLoggedIn = (
  authToken: string,
  user: User,
  save?: boolean
) => async (dispatch: ThunkDispatch) => {
  try {
    // Set the auth token in localStorage
    const defaultExpiration = CONSTANTS.AUTH_TOKEN_DEFAULT_EXPIRATION;
    await refreshLocalStorageAuth(authToken, user, save);
    setTimeout(() => {
      dispatch(logOut());
    }, defaultExpiration * 1000);
    // Fetch user from the server
    if (user) {
      const payload = { authToken, user };
      dispatch({ type: LOG_IN, payload });
    } else {
      throw new Error("Failed to fetch user data");
    }
  } catch (e) {
    dispatch(logOut());
  }
};

// Update user data
export const updateUser = (user: User) => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  try {
    const serverUser = await updateUserService(user);
    dispatch({ type: UPDATE_USER, payload: serverUser });
  } catch (e) {
    throw new Error(e.message);
  }
};

// Logs the user out
export const logOut = () => async (
  dispatch: ThunkDispatch,
  getState: () => AppState
) => {
  try {
    const authToken = getState().Auth.authToken;
    if (authToken) {
      await logOutService(authToken);
    }
    await cleanLocalStorageAuth();
    dispatch({ type: LOG_OUT, payload: null });
  } catch (e) {
    throw new Error(e.message);
  }
};
